import React from 'react'
import { Link } from 'react-router-dom'

export default () => (
  <nav>
    <Link to="/">Workout</Link>
    <span> </span>
    <Link to="/admin">Admin</Link>
    <span> </span>
    <Link to="/login">Login</Link>
  </nav>
)
