import React from 'react'
import pose from 'react-pose'

import style from './Excersices.module.scss'

const UL = pose.ul({
  visible: { opacity: 1, staggerChildren: 50 },
  invisible: { opacity: 0 },
})

const LI = pose.li({
  pressable: true,
  press: { scale: 0.96, opacity: 0.8, color: '#a0d0f8' },
  visible: { opacity: 1, scale: 1, x: 0, color: '#000000' },
  invisible: { opacity: 0 },
})

export const Excersices = props => {
  return (
    <UL initialPose="invisible" pose="visible" className={style.List}>
      {props.children}
    </UL>
  )
}

export function Excersice(props) {
  const { name, meta, onClick } = props
  const { index } = meta
  const movement = meta.movements[index]
  return (
    <LI
      data-name={name}
      name={name}
      className={style.List__Item}
      onClick={event => onClick(event, name)}
      initialPose="invisible"
      pose="visible"
    >
      <strong>{movement}</strong>
    </LI>
  )
}
