import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import style from './AppGeneral.scss'

// Components
import Admin from './Components/Admin'
import Workout from './Components/Workout'
import Navigation from './Components/Navigation'
import Login from './Components/Login'
import AnimatedBox from './Components/AnimatedBox'

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSignedIn: false,
    }
  }

  setSignedIn = isSignedIn => {
    this.setState({ isSignedIn })
  }

  render() {
    const { isSignedIn } = this.state
    const { location } = this.props
    return (
      <Router>
        <div>
          <header className="App-header">
            <Navigation />
          </header>
          <section className="App">
            <Route exact path="/" component={Workout} />
            <Route
              path="/admin"
              render={() =>
                isSignedIn ? (
                  <Admin />
                ) : (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: location },
                    }}
                  />
                )
              }
            />
            <Route
              path="/login"
              render={() => (
                <Login setSignedIn={this.setSignedIn} isSignedIn={isSignedIn} />
              )}
            />
            <Route path="/signedIn" component={Admin} />
            <Route path="/test" component={AnimatedBox} />
          </section>
        </div>
      </Router>
    )
  }
}
