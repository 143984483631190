import React from 'react'

export const Play = id => {
  const sound = document.getElementById(id)
  if (sound) {
    sound.play()
  }
}

export default props => <audio id={props.id} src={props.src} />
