import React, { Component } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from '../Auth/Firebase'

export default class Login extends Component {
  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () =>
        this.setState({ redirectToReferrer: true }),
    },
  }

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    const { setSignedIn } = this.props
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(user => setSignedIn(!!user))
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver()
  }

  render() {
    const { isSignedIn } = this.props
    if (!isSignedIn) {
      return (
        <main>
          <h1>Tjockis</h1>
          <p>Please sign-in:</p>
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </main>
      )
    }
    return (
      <main>
        <h1>My App</h1>
        <p>
          Welcome {firebase.auth().currentUser.displayName}! You are now
          signed-in!
        </p>
        <button type="button" onClick={() => firebase.auth().signOut()}>
          Sign-out
        </button>
      </main>
    )
  }
}
