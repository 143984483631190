import firebase, { database } from 'firebase'

const config = {
  apiKey: 'AIzaSyCEglUoZ8f9kn3PpBmCC9eqaLUrv_H49TM',
  authDomain: 'tjockis-23efe.firebaseapp.com',
  databaseURL: 'https://tjockis-23efe.firebaseio.com',
  projectId: 'tjockis-23efe',
  storageBucket: 'tjockis-23efe.appspot.com',
  messagingSenderId: '285949011262',
}
firebase.initializeApp(config)

export { database }
export default firebase
