import React, { Component } from 'react'
import PropTypes from 'prop-types'

import style from './Countdown.module.scss'

import { Settings } from './Settings'
import { Play } from './Audio'
import IntroCountdown from './IntroCountdown'
import Button from './Button'

export default class Countdown extends Component {
  constructor(props) {
    super(props)
    const { workout: current } = this.props
    this.state = {
      current: current.shift(),
      running: false,
      resting: false,
      introLeft: Settings.introTime,
      workLeft: Settings.workTime,
      restLeft: Settings.restTime,
    }
  }

  componentDidMount() {
    this.isVisible = true
  }

  componentWillUnmount() {
    this.isVisible = false
  }

  wait = (ms = 1000) =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    }).catch(error => {
      console.log(error)
    })

  countDownIntro = () => {
    const { introLeft } = this.state
    this.setState({
      introLeft: introLeft - 1,
    })
  }

  countDownWork = () => {
    const { workLeft } = this.state
    this.setState({
      workLeft: workLeft - 1,
    })
  }

  countDownRest = () => {
    const { restLeft } = this.state
    this.setState({
      restLeft: restLeft - 1,
    })
  }

  ResetTimer = () => {
    this.setState({
      workLeft: Settings.workTime,
      restLeft: Settings.restTime,
    })
  }

  handleTimer = () => {
    const { introLeft, workLeft, restLeft } = this.state
    const { workout, handleExit } = this.props

    // Kolla om vi unmountat och skall stoppa
    if (!this.isVisible) {
      console.log('Not visible, stopping')
      return
    }

    // Vi börjar med introt
    if (introLeft !== 0) {
      this.countDownIntro()
      // Se om vi skall spela ljud
      if (introLeft === 1) {
        Play('beep')
      }
      this.wait().then(this.handleTimer)
    }

    // Se om vi har tid kvar
    else if (workLeft !== 0) {
      this.countDownWork()
      // Se om vi skall spela ljud
      if (workLeft === 1 || workLeft === 16) {
        Play('beep')
      }
      this.wait().then(this.handleTimer)
    } else if (restLeft !== 0) {
      if (restLeft === Settings.restTime || restLeft === 1) {
        this.setState({
          resting: !this.state.resting,
        })
      }
      this.countDownRest()
      // Se om vi skall spela ljud
      if (restLeft === 1) {
        Play('beep')
      }
      this.wait().then(this.handleTimer)
    } else if (workLeft === 0 && restLeft === 0 && workout.length !== 0) {
      console.log('Ok, one more')
      this.setState({
        current: workout.shift(),
        workLeft: Settings.workTime,
        restLeft: Settings.restTime,
      })
      this.wait().then(this.handleTimer)
    } else {
      console.log('We seem to be done')
      handleExit()
    }
  }

  // https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
  handleClick = () => {
    let promise = document.querySelector('audio').play()
    if (promise !== undefined) {
      promise
        .catch(error => {
          console.error(error)
        })
        .then(() => {
          this.setState({
            running: true,
          })
          // Start timer
          this.wait().then(this.handleTimer)
        })
    }
  }

  render() {
    const { current, introLeft, workLeft, restLeft, running } = this.state
    const { handleExit, workout } = this.props

    if (running && introLeft !== 0) {
      return <IntroCountdown>{introLeft}</IntroCountdown>
    }

    return (
      <div
        className={
          this.state.resting
            ? style.CountdownModal__Resting
            : style.CountdownModal
        }
      >
        <h1 className={style.CurrentWork}>{current}</h1>
        <h1 className={style.WorkTime}>{workLeft}</h1>
        <h1 className={style.RestTime}>{restLeft}</h1>
        <div
          className={this.state.resting ? style.UpNext__Resting : style.UpNext}
        >
          {workout.length > 0 && workout[0]}
        </div>
        {running ? (
          <Button type="button" onClick={handleExit}>
            Stop
          </Button>
        ) : (
          <>
            <Button type="button" disabled={running} onClick={this.handleClick}>
              Move
            </Button>
            <Button type="button" onClick={handleExit}>
              Exit
            </Button>
          </>
        )}
      </div>
    )
  }
}

Countdown.propTypes = {
  workout: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleExit: PropTypes.func.isRequired,
}
