import React, { Component } from 'react'
import { database } from '../Auth/Firebase'

import EditableList from './EditableList'

export default class Admin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newMovement: '',
      newMovementCategory: 'cardio',
      movements: [],
    }
  }

  componentDidMount() {
    const movementsDataRef = database()
      .ref()
      .child('movements')

    movementsDataRef.on('value', snapshot => {
      this.setState({
        movements: snapshot.val(),
      })
    })
  }

  handleChange = e => {
    this.setState({ newMovement: e.target.value })
  }

  handleCategoryChange = e => {
    this.setState({ newMovementCategory: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { newMovement, newMovementCategory } = this.state
    const id = Date.now()

    database()
      .ref(`movements/${newMovementCategory}/${id}`)
      .set({
        value: newMovement,
        category: newMovementCategory,
      })

    // Clear the state/field
    this.setState({
      newMovement: '',
    })
  }

  render() {
    const { newMovement, newMovementCategory, movements } = this.state

    return (
      <main>
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            placeholder="Ny rörelse"
            onChange={this.handleChange}
            value={newMovement}
          />
          <select
            value={newMovementCategory}
            onChange={this.handleCategoryChange}
          >
            <option value="strenghtLower">strenghtLower</option>
            <option value="strenghtUpper">strenghtUpper</option>
            <option value="core">core</option>
            <option value="dynamic">dynamic</option>
            <option value="cardio">cardio</option>
            <option value="rest">rest</option>
          </select>
          <button type="submit"> Skicka </button>
        </form>
        <EditableList movements={movements} />
      </main>
    )
  }
}
