import React from 'react'
import { database } from 'firebase'

const deleteItem = (itemID, itemCategory, e) => {
  console.log('CLICKED', itemID, itemCategory, e)
  database()
    .ref(`movements/${itemCategory}/${itemID}`)
    .remove()
}

const Item = ({ name, itemID, itemCategory }) => (
  <li key={name}>
    {name}{' '}
    <button type="button" onClick={e => deleteItem(itemID, itemCategory, e)}>
      X
    </button>
  </li>
)

const createList = movements => {
  if (!movements) {
    return <pre>No movements found</pre>
  }

  const list = Object.keys(movements).map(name => {
    const items = Object.keys(movements[name]).map(move => {
      const itemName = movements[name][move].value
      const itemCategory = movements[name][move].category
      const itemID = move
      return (
        <Item
          key={itemName}
          name={itemName}
          itemID={itemID}
          itemCategory={itemCategory}
        />
      )
    })

    return (
      <section key={`${name}-section`}>
        <h2 key={name}>{name}</h2>
        <ul key={`${name}-ul`}>{items}</ul>
      </section>
    )
  })

  return list
}

export default ({ movements }) => {
  const list = createList(movements)
  return <div>{list}</div>
}
