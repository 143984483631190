import React, { Component } from 'react'
import pose from 'react-pose'
import styles from './IntroCountdown.module.scss'

const IntroCountdownHold = pose.div({
  invisible: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
})

export default class IntroCountdown extends Component {
  render() {
    return (
      <IntroCountdownHold
        initialPose="invisible"
        pose="visible"
        className={styles.IntroCountdown}
      >
        {this.props.children}
      </IntroCountdownHold>
    )
  }
}
