import _ from 'underscore';

export const movementBlocks = {
  strenghtLower: {
    index: 0,
    movements: _.shuffle([
      'Airsquats med tåhävning',
      'Deadlifts',
      'Kliv upp',
      'Utfall bak',
    ]),
  },
  strenghtUpper: {
    index: 0,
    niceName: 'Strength upper',
    movements: _.shuffle(['Armhävningar', 'Push press', 'Ryggflyes']),
  },
  core: {
    index: 0,
    movements: _.shuffle(['Planka', 'Superman vartannat ben', 'Sit ups']),
  },
  dynamic: {
    index: 0,
    movements: _.shuffle(['Kliv igenom', 'Sidostjärna', 'Utfall med rotation']),
  },
  cardio: {
    index: 0,
    movements: _.shuffle(['Burpees', 'MT Climber', 'Borshovhopp', 'Boxhopp']),
  },
  rest: {
    index: 0,
    movements: _.shuffle([
      'REST: Hunden',
      'REST: Squat',
      'REST: Framåtfällning',
    ]),
  },
};

export default movementBlocks;
