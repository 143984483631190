import React, { Component } from 'react'

import posed from 'react-pose'

import Navigation from './Navigation'

const Box = posed.div({
  1: {
    y: -100,
    opacity: 0,
  },
  2: {
    y: 0,
    delay: 100,
    opacity: 1,
  },
  3: {
    y: 75,
    delay: 150,
    opacity: 1,
  },
  4: {
    y: 150,
    delay: 200,
    opacity: 1,
  },
})

class AnimatedBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentWork: 0,
    }
  }

  componentDidMount() {
    setInterval(() => {
      const { currentWork } = this.state
      if (currentWork === 4) {
        this.setState({ currentWork: 0 })
      } else {
        this.setState({ currentWork: currentWork + 1 })
      }
    }, 2000)
  }

  getCurrentOrder = order => {
    const { currentWork } = this.state
    const thisOrder = order + 1 - currentWork

    if (thisOrder < 2) {
      return 1
    }
    return thisOrder
  }

  render() {
    return (
      <>
        <Box pose={this.getCurrentOrder(1)} className="box">
          <h1 className="time">45</h1>
        </Box>
        <Box pose={this.getCurrentOrder(2)} className="box2">
          <h1 className="time">45</h1>
        </Box>
        <Box pose={this.getCurrentOrder(3)} className="box3">
          <h1 className="time">45</h1>
        </Box>
      </>
    )
  }
}

export default AnimatedBox
