import React from 'react'
import _ from 'underscore'
import { database } from '../Auth/Firebase'

import { Excersices, Excersice } from './Excersices'
import Countdown from './Countdown'
import Loading from './Loading'
import { movementBlocks } from '../Data/movementBlocks'
import Audio from './Audio'
import Button from './Button'

export default class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      appTitle: 'Tjoockis',
      movementBlocks: false,
      workout: [],
    }
  }

  componentDidMount() {
    this.getMovements()
    const appTitleRef = database()
      .ref()
      .child('appTitle')

    appTitleRef.on('value', snapshot => {
      this.setState({
        appTitle: snapshot.val(),
      })
    })
  }

  getMovements = () => {
    database()
      .ref(`movements`)
      .once('value')
      .then(snapshot => {
        // Foreach category
        snapshot.forEach(category => {
          const newArr = []
          const catKey = category.key

          // And foreach item
          category.forEach(item => {
            const itemVal = item.val().value
            newArr.push(itemVal)
          })
          movementBlocks[catKey].movements = _.shuffle(newArr)
        })
      })
      .then(() => {
        console.log(movementBlocks)

        // Set state
        this.setState({
          movementBlocks,
        })
      })
  }

  createWorkout = () => {
    const { movementBlocks: mb } = this.state
    // Get displayed excercises and compile into
    // a workout.
    const workout = [
      mb.strenghtLower.movements[mb.strenghtLower.index],
      mb.strenghtUpper.movements[mb.strenghtUpper.index],
      mb.core.movements[mb.core.index],
      mb.dynamic.movements[mb.dynamic.index],
      mb.cardio.movements[mb.cardio.index],
      mb.rest.movements[mb.rest.index],
    ]

    this.setState({
      workout,
    })
  }

  clearWorkout = () => {
    this.setState({
      workout: [],
    })
  }

  nextExcersice = (event, name) => {
    const { movementBlocks: mbs } = this.state
    const movementBlocksCopy = { ...mbs }

    // SÅ plussa indexet om den inte är på sista
    // DÅ sätter vi den till noll och börjar om.
    movementBlocksCopy[name].index =
      movementBlocksCopy[name].index <
      movementBlocksCopy[name].movements.length - 1
        ? movementBlocksCopy[name].index + 1
        : 0
    this.setState({
      movementBlocks: movementBlocksCopy,
    })
  }

  render() {
    const { appTitle, workout, movementBlocks: blocks } = this.state

    return (
      <main>
        <Audio id="beep" src="/media/beep-09.mp3" />
        <h1>Hello {appTitle}</h1>
        {blocks ? (
          <div>
            <Excersices>
              {Object.keys(blocks).map(key => (
                <Excersice
                  key={key}
                  name={key}
                  meta={blocks[key]}
                  onClick={this.nextExcersice}
                />
              ))}
            </Excersices>
            <Button type="button" onClick={this.createWorkout}>
              Create
            </Button>
            {workout.length > 0 && (
              <Countdown workout={workout} handleExit={this.clearWorkout} />
            )}
          </div>
        ) : (
          <Loading />
        )}
      </main>
    )
  }
}
